import moment from 'moment/moment';

export default {
  duration(value, unit, locale = 'fr') {
    if (unit !== 'minutes') {
      return value;
    }

    if (value < 60) {
      return `${value} min`;
    }

    if (value < 24 * 60) {
      return `${Math.floor(value / 60)}h${value % 60 > 0 ? value % 60 : ''}`;
    }

    if (value >= 364 * 24 * 60 && value < 366 * 24 * 60) {
      return `1 ${Translator.trans('general.year')}`;
    }

    const dayLabel = `${Translator.trans('general.day', {}, null, locale)}${value / 60 / 24 > 1 ? 's' : ''}`;
    return `${Math.floor(value / 60 / 24)} ${dayLabel}`;
  },
  height(value) {
    if (value < 100) {
      return `${value} cm`;
    }
    return `${Math.floor(value / 100)}m${value % 100 > 0 ? value % 100 : ''}`;
  },
  /**
   *
   * @param {null|Date|string} before
   * @param {null|Date|string} after
   */
  dateLimits({ before, after }) {
    const formatValue = (v) => (v instanceof Date ? v.toLocaleDateString() : v);
    const formatTemplate = (strings, ...keys) => {
      const result = [strings[0]];
      keys.forEach((key, i) => {
        const value = formatValue(key);
        result.push(value, strings[i + 1]);
      });
      return result.join('');
    };
    if (before && after) {
      return formatTemplate`Entre le ${before} et le ${after}`;
    }

    const val = before || after;
    const formattedVal = val instanceof Date ? val.toLocaleDateString() : val;
    return `${Translator.trans(`datatable.filters.${before ? 'before' : 'after'}`, 'datatable')} : ${formattedVal}`;
  },

  /**
   *
   * @param {null|Date|string} from
   * @param {null|Date|string} to
   */
  dateBetween({ from, to }) {
    if (from && to) {
      const innerFrom = typeof from === 'string' ? new Date(from) : from;
      const innerTo = typeof to === 'string' ? new Date(to) : to;
      return Translator.trans(
        'datatable.filters.between',
        {
          from: `${innerFrom.toLocaleDateString()} ${innerFrom.toLocaleTimeString()}`,
          to: `${innerTo.toLocaleDateString()} ${innerTo.toLocaleTimeString()}`,
        },
        'datatable'
      );
    }
    return '';
  },

  /**
   * @param {undefined|number} gt
   * @param {undefined|number} lt
   * @param {undefined|number} gte
   * @param {undefined|number} lte
   * @return {string}
   */
  rangeLimits({ gt, lt, gte, lte }) {
    // if (!gt && !gte && !lt && !lte) {
    //   throw new Error('RangeLimitsFormatter : Missing limits');
    // }
    //
    // if ((gt && gte) || (lt && lte)) {
    //   throw new Error('RangeLimitsFormatter : competing limits found');
    // }

    if ((!lt && !lte) || (!gt && !gte)) {
      const limit = !lt && !lte ? 'À partir de' : "Jusqu'à";
      return `${limit} ${lt || lte || gt || gte}€`;
    }

    return `Entre ${gt || gte}€ et ${lt || lte}€`;
  },

  formatDate(value, format = 'DD/MM/YYYY HH:mm') {
    if (value) {
      return moment(String(value)).format(format);
    }
    return value;
  },
};

export function anonymizeEmail(email) {
  email = email ?? '';
  const em = email.split('@');
  const name = em[0];

  // count half characters length to hide
  //        length = floor(strlen(name) / 2);

  // Replace half characters with * symbol
  return `${name.slice(0, 3)}${'*'.repeat((name.length < 3 ? 3 : name.length) - 3)}@${em[1]}`;
}

export function anonymize(v) {
  const shownLength = Math.min(3, Math.round(v.length / 3));
  return `${v.slice(0, shownLength)}${'*'.repeat((v.length ?? shownLength) - shownLength)}`;
}
